import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BankIdState } from '~/utils/authBankId';

// Define the state interface
export interface ApplicationSubmitState {
  advancementId: string | null;
  bankIdState: BankIdState;
  linkToOpenBankId: string | null;
  isDisabled: boolean;
  isCancelled: boolean;
  isSubmitting: boolean;
  bankIdLoading: boolean;
  hasPhone: boolean,
  hasSsn: boolean,
}

// Define the initial state
const initialState: ApplicationSubmitState = {
  advancementId: null,
  bankIdState: BankIdState.Initial,
  linkToOpenBankId: null,
  isDisabled: false,
  isCancelled: false,
  isSubmitting: false,
  bankIdLoading: false,
  hasPhone: false,
  hasSsn: false,
};

// Create the slice
export const applicationSubmitSlice = createSlice({
  name: 'applicationSubmit',
  initialState,
  reducers: {
    setBankIdState: (state, action: PayloadAction<BankIdState>) => {
      state.bankIdState = action.payload;
    },
    setAdvancementId: (state, action: PayloadAction<string | null>) => {
      state.advancementId = action.payload;
    },
    setIsDisabled: (state, action: PayloadAction<boolean>) => {
      state.isDisabled = action.payload;
    },
    setIsSubmitting: (state, action: PayloadAction<boolean>) => {
      state.isSubmitting = action.payload;
    },
    setIsCancelled: (state, action: PayloadAction<boolean>) => {
      state.isCancelled = action.payload;
    },
    setBankIdLoading: (state, action: PayloadAction<boolean>) => {
      state.bankIdLoading = action.payload;
    },
    setLinkToOpenBankId: (state, action: PayloadAction<string | null>) => {
      state.linkToOpenBankId = action.payload;
    },
    setHasPhone: (state, action: PayloadAction<boolean>) => {
      state.hasPhone = action.payload;
    },
    setHasSsn: (state, action: PayloadAction<boolean>) => {
      state.hasSsn = action.payload;
    },
    resetAll: (state) => {
      Object.keys(state).forEach((key) => {
        (state as any)[key] = (initialState as any)[key];
      });
    }
  },
});

// Export the actions and the reducer
export const {
  setBankIdState,
  setAdvancementId,
  setIsDisabled,
  setIsSubmitting,
  setIsCancelled,
  setBankIdLoading,
  setLinkToOpenBankId,
  setHasPhone,
  setHasSsn,
  resetAll
} = applicationSubmitSlice.actions;

export default applicationSubmitSlice.reducer;
