import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductType } from '~/sdk/internal/v2/company/applications';

export interface GuarantorMeta {
  isActive: boolean,
  isNew: boolean,
  isSelected: boolean,
  isInit?: boolean,
  isEditing?: boolean,
  isComplete?: boolean,
  isBoardMember?: boolean,
}

export interface GuarantorData {
  id?: number,
  name?: string,
  phone?: string,
  email?: string,
  ssn: number,
  role?: string,
  approvedFor: ProductType[]
}

export type UpdateGuarantorMetaPayload = {
  id: number;
  meta: Partial<GuarantorMeta>;
};

export type GuarantorCreatePayload = {
  ssn: number,
  email: string,
  phone: number,
}

export type UpdateGuarantorDataPayload = {
  id: number;
  data: Partial<GuarantorData>;
};

type StatusType = 'loading' | 'init' | 'creating' | 'patching' | 'fetching' | 'ready';

export type GuarantorsState = {
  entities: Record<number, GuarantorData>;
  meta: Record<number, GuarantorMeta>;
  selectedGuarantors: number[];
  selectedBoardMembers: number[];
  loading: boolean;
  error: string | undefined;
  status: StatusType;
};

const initialState: GuarantorsState = {
  status: 'init',
  entities: {},
  meta: {},
  selectedGuarantors: [],
  selectedBoardMembers: [],
  loading: false,
  error: undefined,
};

const guarantorsSlice = createSlice({
  name: 'guarantors',
  initialState,
  reducers: {
    updateGuarantorMeta: (state, action: PayloadAction<UpdateGuarantorMetaPayload>) => {
      const { id, meta } = action.payload;
      state.meta[id] = { ...state.meta[id], ...meta };
    },
    updateGuarantorData: (state, action: PayloadAction<UpdateGuarantorDataPayload>) => {
      const { id, data } = action.payload;
      if (id) state.entities[id] = { ...state.entities[id], ...data };
    },
    removeGuarantor: (state, action: PayloadAction<number>) => {
      delete state.entities[action.payload];
      delete state.meta[action.payload];
    },
    setSelectedGuarantors: (state, action: PayloadAction<number[]>) => {
      state.selectedGuarantors = [...action.payload];
    },
    setSelectedBoardMembers: (state, action: PayloadAction<number[]>) => {
      state.selectedBoardMembers = [...action.payload];
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setStatus: (state, action: PayloadAction<StatusType>) => {
      state.status = action.payload;
    },
    resetGuarantorState: (state) => {
      state.entities = {};
      state.meta = {};
    },
  },
  extraReducers: (builder) => {
    builder
  }
});

export const {
  updateGuarantorMeta,
  updateGuarantorData,
  removeGuarantor,
  setSelectedGuarantors,
  setSelectedBoardMembers,
  setStatus,
  resetGuarantorState
} = guarantorsSlice.actions;

export default guarantorsSlice.reducer;
