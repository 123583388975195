import { createSlice, createAction } from '@reduxjs/toolkit';
import { ProductKeys, InvestmentTypeType } from '~/hooks/useOnboarding';

export type AmountsType = {
  [key in ProductKeys]: number
}

export interface ApplicationState {
  currentCompanyId: number | undefined,
  useOnboarding: boolean,
  onboarding: {
    skipProvider: boolean,
    availableAmounts: AmountsType,
    desiredAmount: number,
    investmentType?: InvestmentTypeType,
    excluded?: ProductKeys,
  }
}

const initialState: ApplicationState = {
  useOnboarding: false,
  currentCompanyId: undefined,
  onboarding: {
    skipProvider: false,
    excluded: undefined,
    investmentType: undefined,
    desiredAmount: 0,
    availableAmounts: {
      'si': 0,
      'bl': 0,
      'id': 0
    },
  }
};

// action to update the sync status
export const setCurrentCompanyId = createAction<number | undefined>('providers/setCurrentCompanyId');
export const updateAmount = createAction<AmountsType>('providers/updateAmount');
export const updateDesiredAmount = createAction<number>('providers/updateDesiredAmount');
export const setInvestmentType = createAction<InvestmentTypeType>('providers/setInvestmentType');
export const setExcluded = createAction<ProductKeys | undefined>('providers/setExcluded');
export const setSkipProvider = createAction<boolean>('providers/skipProvider');
export const setUseOnboarding = createAction<boolean>('providers/useOnboarding');

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setCurrentCompanyId, (state, action) => {
      state.currentCompanyId = action.payload;
    })
    builder.addCase(updateAmount, (state, action) => {
      state.onboarding.availableAmounts = { ...state.onboarding.availableAmounts, ...action.payload };
    })
    builder.addCase(updateDesiredAmount, (state, action) => {

      // if amount is set, we're onboarding
      state.useOnboarding = action.payload > 1000;
      state.onboarding.desiredAmount = action.payload;
    })
    builder.addCase(setUseOnboarding, (state, action) => {
      state.useOnboarding = action.payload;
    })
    builder.addCase(setInvestmentType, (state, action) => {
      state.onboarding.investmentType = action.payload;
    })
    builder.addCase(setExcluded, (state, action) => {
      state.onboarding.excluded = action.payload;
    })
    builder.addCase(setSkipProvider, (state, action) => {
      state.onboarding.skipProvider = action.payload;
    })
  }
});

export default applicationSlice.reducer;
